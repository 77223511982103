import React from 'react';
import SEO from '../components/seo';
import Blue from '../images/Blue.svg';
import '../styles/global.scss';
import 'normalize.css';

const TermsNconditions = () => {
  return (
    <section
      className='section'
      style={{ padding: '80px', backgroundColor: '#fff', fontFamily: 'Muli' }}
    >
      <SEO title='Terms and Condition' pathname='TermsNconditions' />
      <center>
        <img
          src={Blue}
          width='200px'
          style={{ paddingBottom: '50px' }}
          alt='logo'
        />
      </center>
      <div style={{ fontFamily: 'Muli', color: '#000' }}>
        <p>
          Welcome to “GoNuclei" (“<strong>Platform</strong>”). The content of
          the Platform is the property of CDNA Technologies Private Limited (the
          “<strong>Company</strong>” or “<strong>Us</strong>” or “
          <strong>We</strong>” or “<strong>Our</strong>”). We are an information
          technology company inter-alia engaged in the business of building
          software products, the Company has developed a proprietary Platform,
          which can be integrated with various mobile banking Platforms to
          provide merchant aggregation, user engagement services (
          <em>both directly and indirectly</em>), and enables transactions and
          certain other services through Company’s merchant network Platform
        </p>
        <p>
          Your use of the Platform and the Services (as defined below) is
          governed by the following terms and conditions (“
          <strong>Terms of Use</strong>”). If you do not agree to these Terms of
          Use, you cannot use the Platform.
        </p>
        <p>
          We provide the Services in a direct and indirect form, as the case may
          be i.e. as a merchant/reseller or as an intermediary. Therefore, these
          Terms of Use have been divided into three parts namely Part A
          (applicable to Services provided by Company as a Merchant, to be
          referred as “<strong>Direct Services</strong>”), Part B (applicable to
          Services provided by Company as an intermediary, to be referred as “
          <strong>Indirect Services</strong>”) and Part C (terms and conditions
          applicable to provision of both Direct Services and Indirect
          Services). Collectively the Direct Services and Indirect Services are
          referred to as “<strong>Services</strong>”.
        </p>
        <p>
          Depending on the kind of Service utilised by You through the Platform
          i.e. Direct Services or Indirect Services, Part A or Part B may be
          applicable respectively, as the case may be. Whereas, Part C of these
          Terms of Use is applicable to you regardless of the kind of Service
          availed by You.
        </p>
        <h4
          style={{
            fontSize: '20px',
            fontWeight: '700',
            padding: '20px 10px 20px 0px',
          }}
        >
          Platform
        </h4>
        <p>
          By using the Platform , you signify your agreement to these Terms of
          Use as well as the Company’s privacy policy, which is hereby
          incorporated by reference herein. These Terms of Use set out the
          legally binding terms of the Services as well as the terms of use of
          the Platform.
        </p>
        <p>
          For the purpose of these Terms of Use, wherever the context so
          requires, “<strong>you</strong>” or “<strong>your</strong>” shall mean
          any natural or legal person who visits the Platform and/or transacts
          business on the Platform and / or uses the Services by providing
          registration information on the respective bank’s mobile Platform.
        </p>
        <p>
          <span>
            <u>
              Please read these terms of use carefully before using the platform
              to avail the services
            </u>
          </span>
        </p>
        <p>
          These Terms of Use along with the Company’s privacy policy extend to
          users who visit the Platform but do not avail the Services or transact
          on the Platform as well as users who are registered and are authorized
          by the Platform to transact business or avail the Services on the
          Platform. We reserve the right to modify or terminate any portion of
          the Platform or the Services offered by Us for any reason, without
          notice and without liability to you or any third party. You are
          responsible for regularly reviewing these Terms of Use so that you
          will be apprised of any changes. Nothing in these Terms of Use should
          be construed to confer any rights to any third party beneficiaries.
        </p>
        <div id='PartA'>
          <br />
          <br />
          <h1
            style={{
              textAlign: 'center',
              fontSize: '30px',
              fontFamily: 'Open Sans',
            }}
          >
            PART A: Terms and conditions applicable for services provided by
            Company
          </h1>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Provision of Direct Services
          </h4>
          <p>
            The Platform can be utilised by You to procure Direct Services
            provided by Us. Certain services categorised as Direct Services are
            directly provided by Us and We control all the transactions in
            relation to Direct Services.
          </p>
          <p>
            The commercial/contractual terms regulating the provision of Direct
            Services are provided by Us.&nbsp;The commercial/contractual terms
            include without limitation, price, payment methods, payment terms,
            date, period and mode of delivery (<em>if applicable</em>),
            warranties related to products and services and after-sales services
            related to any of the Direct Services.
          </p>
          <p>
            We at all times retain the right to engage an agent or a
            sub-contractor to offer/provide/render the Direct Services to You.
          </p>
          <p>
            You are advised to independently verify the bona fides of any
            particular Direct Services that you choose to avail and use your
            best judgment in that regard.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Cancellation
          </h4>
          <p>
            You may cancel Your order for the any of Direct Services after
            placing the order by making a cancel request with Us through the
            Platform or through such other modes of cancelation provided by Us.
            In such a case We may refund any payments already made by You for
            the order received at Our discretion. If we suspect any fraudulent
            transaction by You or any transaction which is in violation of these
            terms and conditions, we, at our sole discretion, reserve the right
            to initiate the cancellation of such orders placed by You.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Pricing and Product Availability
          </h4>
          <p>
            We will display on the Platform, information on price and
            availability of the Direct Services provided by Us. The information
            available on the Platform is displayed by Us in an ‘as is’ and ‘as
            available’ form. For any order placed by You, the price shall be the
            price prevailing at the date on which the order is placed. The
            prices listed on the Platform are not negotiable.
          </p>
          <p>
            Despite the best efforts of the Company, such information on the
            price and availability of the Direct Services may be inaccurately
            displayed on the Platform. The Company reserves the right to correct
            any and all errors when they do occur, and the Company does not
            honour inaccurate or erroneous prices. The prices on the Platform
            are also subject to change without notice.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Payment
          </h4>
          <p>
            The Company offers multiple payment options to You on the Platform
            for making payments for the Direct Services availed by You. The
            Payments made by You are to be directly made by You to the bank
            account as designated by Us and which will be informed to You at the
            time of making the payment.
            <br />
            <strong>Online Payment</strong>: You can make online payments using
            your credit card or debit card or through net banking or directly
            through the bank’s mobile application through which you have
            accessed the Platform or through mobile wallets or any other payment
            methods as made available to You by Us or by the bank either through
            the Platform or through bank’s mobile application which You use for
            accessing the Platform.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Delivery
          </h4>
          <p>
            Delivery of the Direct Services may be dependent on third party
            service providers or sub-contractors engaged by Us. We will ensure
            that the Direct Services provided by Us are provided to You without
            any delay, but We do not warrant the delivery of the Services
            without any delay.
          </p>
        </div>
        <div id='PartB'>
          <br />
          <br />
          <h1
            style={{
              textAlign: 'center',
              fontSize: '30px',
              fontFamily: 'Muli',
            }}
          >
            PART B: Terms and conditions applicable for services provided by
            third party sellers on the Platform.
          </h1>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Platform for Transaction and Communication
          </h4>
          <p>
            The Platform is a software tool that You may utilise to procure
            Indirect Services from various suppliers or Sellers (
            <em>as defined below</em>) for their requirements. The Company is
            not and under no circumstances will be a party to or control in any
            manner any transaction between You and third party suppliers/Sellers
            in relation to Indirect Services.All commercial/contractual terms
            are offered by and agreed to between you and the Sellers of the
            Indirect Services (“<strong>Seller/s</strong>”) alone. The
            commercial/contractual terms include without limitation, price,
            shipping costs or handling charges, payment methods, payment terms,
            date, period and mode of delivery (<em>if applicable</em>),
            warranties related to services. We do not have any control or do not
            determine or advise or in any way involve itself in the offering or
            acceptance of such commercial/contractual terms between you and the
            Sellers. Once you agree to avail a service from a Seller, you also
            agree to be bound by the terms and conditions of use, of the Seller.
            All transactions between you and the Seller will be governed by the
            terms and conditions provided by the Seller and as may be
            applicable.
          </p>
          <p>
            We do not make any representations or warranties as to specifics
            (such as quality, value, saleability, etc) of the Indirect Services
            proposed to be offered or availed on the Platform. The Company does
            not implicitly or explicitly support or endorse the sale or purchase
            of any Indirect Services on the Platform. The Company accepts no
            liability for any errors or omissions, whether on behalf of itself
            or third parties or the Sellers. We are not responsible for any
            non-performance or breach of any contract entered into between you
            and the Sellers. We cannot and do not guarantee that you and/or the
            Sellers will perform any transaction concluded on the Platform. We
            are not and are not required to mediate or resolve any dispute or
            disagreement between you and the Sellers. You are advised to
            independently verify the bona fides of any particular Seller that
            you choose to deal with on the Platform and use your best judgment
            in that regard. We do not at any point of time during any
            transaction between you and the Seller on the Platform gain title to
            or have any rights or claims over the Indirect Services offered by
            the Seller to you. We are not responsible for unsatisfactory or
            delayed performance of the Indirect Services or damages or delays as
            a result of products which are out of stock, unavailable or back
            ordered. It is agreed that the contract for sale of any of the
            services shall be a strictly bipartite contract between the Seller
            and you and the Company under no circumstances will be privy to that
            agreement. You shall independently agree upon the manner and terms
            and conditions of delivery, payment, insurance etc. with the
            Seller(s) that you transact with. Pricing on any Indirect Services
            as is reflected on the Platform may due to some technical issue,
            typographical error or product information published by the Seller
            be incorrectly reflected and in such an event the Seller reserves
            the right to refuse to provide the Indirect Services .
          </p>
          <p>
            The Company does not claim any ownership rights in the text, files,
            images including photos, videos, sounds, musical works or any UGC (
            <em>as defined below</em>) that you submit, submit or publish on the
            Platform. After posting any UGC on the Platform, you continue to own
            the rights that you may have in that UGC, subject to the limited
            license set out here.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Cancellation
          </h4>
          <p>
            You may cancel your order after placing the order in accordance with
            the order cancelation policy of the Seller through the Platform or
            through such other modes of cancelation provided by the Seller.. In
            such a case the Seller may refund any payments already made by you
            for the order, based on the Seller’s refund policy. If we suspect
            any fraudulent transaction by you or any transaction which violates
            this terms and conditions of using the Platform, we, at our sole
            discretion, reserve the right to initiate cancellation of such
            orders placed by you.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Pricing and Product Availability
          </h4>
          <p>
            We will display on the Platform, information on price and
            availability of the Indirect Services rendered , as per the prices
            and service information provided and uploaded by the Sellers. The
            Company do not control the information in relation to pricing and
            services being provided by the Seller in relation to the Indirect
            Services, the information available on the Platform is displayed by
            the Company in an ‘as is’ and ‘as available’ form. For any order
            placed by you, the price shall be the price prevailing at the date
            on which the order is placed. The prices listed on the Platform are
            not negotiable.
          </p>
          <p>
            Despite the best efforts of the Company, such information on the
            price and availability of the Indirect Services may be inaccurately
            displayed on the Platform. We reserve the right to correct any and
            all errors when they do occur, and the We do not honor inaccurate or
            erroneous prices. The prices on the Platform are also subject to
            change without notice.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Payment
          </h4>
          <p>
            The Company offers multiple payment options to you on the Platform.
            The payments made by you are collected by Us on behalf of the
            Seller, upon seeking prior authorization.
            <br />
            <strong>Online Payment</strong>: You can make online payments using
            your credit card or debit card or through net banking or directly
            through the bank’s mobile application through which you have
            accessed the Platform or through mobile wallets or any other payment
            methods whichever is available on your Platform;
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Delivery
          </h4>
          <p>
            The delivery of Indirect Services is based on the Seller’s internal
            policy and We shall not, at any time, be responsible for delivery of
            any Indirect Services.
          </p>
        </div>
        <div id='PartC'>
          <br />
          <br />
          <h1
            style={{
              textAlign: 'center',
              fontSize: '30px',
              fontFamily: 'Muli',
            }}
          >
            PART C: General terms and conditions applicable to all users of the
            Platform.
          </h1>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Access
          </h4>
          <p>
            You are responsible for maintaining the confidentiality of your
            account and password and for restricting and preventing unauthorized
            access to your account. You agree to accept responsibility for all
            activities that occur under your account or password.
          </p>
          <p>
            Use of the Platform is available only to persons who can form
            legally binding contracts under Indian Contract Act, 1872. Persons
            who are "incompetent to contract" within the meaning of the Indian
            Contract Act, 1872 including minors, un-discharged insolvents etc.
            are not eligible to use the Platform.
            <span className='Apple-converted-space'>&nbsp;</span>
          </p>
          <p>
            You represent and warrant that you are at least 18 (Eighteen) years
            old and all information you submit is true, accurate and complete
            while registering the account on Platform. If you are a minor i.e.
            under the age of 18 (Eighteen) years, you shall not transact on or
            use the Platform. As a minor if you wish to use or transact on the
            Platform, such use or transaction may be made by your legal guardian
            or parents.
          </p>
          <p>
            The Company will endeavor to ensure that access to and availability
            of the Platform remains uninterrupted and error free. However,
            access to the Platform may occasionally be suspended or restricted
            to allow for repairs, maintenance, or for the introduction of new
            facilities and services.
          </p>
          <p>
            The Company provides users access to discussion or chat with
            experts. The Platform may therefore contain user generated content
            (“
            <strong>UGC</strong>”) which we do not pre-screen, and which
            contains views that may be opinions of users and also of experts.
            These views do not represent our views, opinions, beliefs, morals or
            values. We will do our best to monitor, edit or remove such UGC
            where we consider it appropriate or necessary to do so. We cannot
            promise that the content in or on the Platform is accurate, complete
            or updated, that it will not offend or upset any person or that it
            does not infringe the intellectual property rights of third parties.
          </p>
          <p>
            <span>Cookie Policy</span>: A cookie is a small text file that we
            place on your mobile device to enable various features of the
            Platform. "Cookies" are used to store user preferences and to track
            user trends, so as to enhance your interactive experience and
            generally improve our Services to you. You can change your cookie
            settings to accept or not accept cookies in your browser settings.
            If you do accept a "cookie", you thereby agree to our use of any
            personal information collected by us using that Cookie.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Limited Licence for Access
          </h4>
          <p>
            We grant you a limited licence to access and make personal use of
            the Platform, but not to download (other than page caching) or to
            modify it (or any portion thereof), except with prior written
            consent of the Company and / or its affiliates, as may be
            applicable.
          </p>
          <p>
            This licence does not include any resale or commercial use of the
            Platform or its contents; any derivative use of the Platform or its
            contents, any downloading or copying of account information for the
            benefit of another seller, or any use of data mining, robots, or
            similar data gathering and extraction tools is prohibited.
          </p>
          <p>
            The Platform or any portion thereof (including but not limited to
            any copyrighted material, trademarks, or other proprietary
            information) may not be reproduced, duplicated, copied, sold,
            resold, visited, distributed or otherwise exploited for any
            commercial purpose without Our prior written consent.
          </p>
          <p>
            You will not frame or use framing techniques to enclose any
            trademark, logo, or other proprietary information (including images,
            text, page layout, or form) of the Company and its affiliates
            without their prior written consent. You will not use any meta tags
            or any other "hidden text" utilizing the Company’s or its
            affiliates' names or trademarks without the prior written consent of
            the Company and / or its affiliates, as may be applicable. Any
            unauthorized use will terminate the permission or license granted by
            the Company and / or its affiliates, as may be applicable.
          </p>
          <p>
            You will not use any logo or other proprietary graphic or trademark
            of the Company/ Platform as part of the link without prior written
            consent of the Company and / or its affiliates, as may be
            applicable.
          </p>
          <p>
            You shall not use the Platform in any way that causes or may be
            likely to cause damage or impairment to the Platform or in any
            manner harms the Company or any other person or entity (as
            determined by the Company in its sole discretion) or interrupt free
            access to it in any way. You are solely responsible for the UGC that
            you post on the Platform.
            <span className='Apple-converted-space'>&nbsp;</span>
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Representations and Warranties
          </h4>
          <p>You hereby represent and warrant that:</p>
          <ul style={{ listStyleType: 'lower-alpha' }}>
            <li>
              you shall not use the Platform to host, display, upload, post,
              submit, distribute, modify, publish, transmit, update or share any
              information that:
              <ul style={{ listStyleType: 'lower-roman' }}>
                <li>
                  belongs to another person and to which you do not have any
                  right;
                </li>
                <li>
                  is grossly harmful, harassing, blasphemous defamatory obscene,
                  pornographic, paedophilic, libellous, invasive of another's
                  privacy, hateful, or racially, ethnically objectionable,
                  disparaging, relating or encouraging money laundering or
                  gambling, or otherwise unlawful in any manner whatever;
                </li>
                <li>harms minors in any way;</li>
                <li>
                  infringes any patent, trademark, copyright or other
                  proprietary rights;
                </li>
                <li>
                  infringes upon any third party’s right of privacy, personality
                  or endorsement;
                </li>
                <li>
                  violates these Terms of Use or any applicable law for the time
                  being in force including, but not limited to, those governing
                  false advertising, consumer protection and safety,
                  discrimination, terror and hate speech;
                </li>
                <li>
                  advertises or promotes anything including personal or
                  commercial sites/Platforms.
                </li>
                <li>
                  deceives or misleads the addressee about the origin of such
                  messages or communicates any information which is grossly
                  offensive or menacing in nature;
                </li>
                <li>impersonates another person;</li>
                <li>
                  contains software viruses or any other computer code, files or
                  programs designed to interrupt, destroy or limit the
                  functionality of the Platform and/or the Services or any
                  connected network or any computer resource, or otherwise
                  interferes with any entity’s use or enjoyment of the Platform
                  and/or the Services; and
                </li>
                <li>
                  threatens the unity, integrity, defence, security or
                  sovereignty of India, friendly relations with foreign states,
                  or public order or causes incitement to the commission of any
                  cognizable offence or prevents investigation of any offence or
                  is insults any other nation.
                </li>
              </ul>
              This is a non-exhaustive list which should be used as a guide on
              what you must not post on the Platform or transmit to other users.
            </li>
            <li>
              You will not engage in any form of antisocial, disrupting, or
              destructive acts, including "flaming," "spamming," "flooding,"
              "trolling," "phishing" and "griefing" as those terms are commonly
              understood and used on the internet.
            </li>
            <li>
              You will not delete or modify any content of the Platform and/or
              Services including but not limited to, legal notices, disclaimers
              or proprietary notices such as copyright or trademark symbols,
              logos, that you do not own or have express permission to modify.
            </li>
            <li>
              You have not received any notice from any third party or any
              governmental authority and no litigation is pending against you in
              any court of law, which prevents you from accessing the Platform
              and/or availing the Services.
            </li>
            <li>
              You are legally authorised to view and access the Platform and
              avail the Services.
            </li>
          </ul>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Usage of promo code by the Company
          </h4>
          <p>
            We have the right to refuse the usage of any promo code at any point
            of time. Promo codes which have expired cannot be used on the
            Platform. We can at any time ask you for the proof of having
            received the promo code. Promo codes can be used only on specific
            orders as deemed fit by Us. Promo codes, at the Our discretion, are
            applicable on certain items, on specific orders, or on specific
            dates and occasions. Promo codes cannot be clubbed with any other
            special offer running on the Platform. For a payment received after
            the expiry of the promo code the discount will not be applicable on
            that order.
            <span className='Apple-converted-space'>&nbsp;</span>
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Nuclei Cash
          </h4>
          <p>
            The following terms and conditions provide for the nature and scope
            of “Cash” and limitations and exclusions on the liability of CDNA
            Technologies Private Limited (“Company”) with respect to the same.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Definitions
          </h4>
          <p>
            “Nuclei Platform” shall mean shall mean the Mobile phone Platform,
            gonuclei.com which provides personal assistance to users through a
            technology based interface connecting experts/sellers and consumers
            of products and services.
          </p>
          <p>
            “Customer” shall mean a person who has an account on the Nuclei
            Platform.
          </p>
          <p>
            “Nuclei Cash” shall mean such amount credited as loyalty points
            earned into the Customer’s account, for specific activities on the
            Nuclei app as per the terms of the specific offer available from
            time to time.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            General Terms
          </h4>
          <p>
            Customers can accrue Nuclei Cash through a completed transaction on
            Nuclei Platform for purchase of goods or services paid by cash,
            credit/debit card, cheque, or online payment.
          </p>
          <p>
            The Company may in its sole and absolute discretion provide
            additional criteria for accrual of Nuclei Cash from time to time.
          </p>
          <p>
            The Company may change any of the terms and conditions herein at any
            time without notice to the Customer. It is the responsibility of the
            Customer to read these terms and conditions from time to time.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Nuclei Cash terms of Accrual
          </h4>
          <p>
            Nuclei Cash will be credited within 48 hours once a transaction is
            complete.
          </p>
          <p>
            Nuclei Cash will not be credited in the Customer’s account in case
            such Customer decides to return the goods or cancel the services
            availed through the Nuclei Platform.
          </p>
          <p>
            Nuclei Cash gets accrued only on Out of Pocket payments on the
            Net-Order Value.
          </p>
          <p>
            Nuclei Cash is non-transferable, non-negotiable, and irredeemable by
            cash. It cannot be gifted or assigned to any other person whether
            Customer or otherwise. Nuclei Cash of one Customer cannot be
            combined with that of any other Customer.
          </p>
          <p>
            The Nuclei Cash balance accrued will expire in case there are no
            credits for 60 (sixty) days. Such expiration is not affected by any
            uninstall or reinstall of the Nuclei Platform. The Customer shall
            not be entitled to redeem any expired Nuclei Cash.
          </p>
          <p>
            In case there is failure in payment transaction, the Customer shall
            be refunded through Nuclei Cash. Such refund through Nuclei Cash is
            valid for 1 (one) year from the date of credit to the Customer’s
            account.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Redemption of Nuclei Cash
          </h4>
          <p>
            Nuclei Cash can be redeemed through the Nuclei Platform only against
            Recharge, Bill Payment &amp; Purchase of Internal Deals.
          </p>
          <p>
            By redeeming Nuclei Cash, the Customer releases the Company from any
            and all liability regarding the redemption of Nuclei Cash.
          </p>
          <p>
            The Company is not responsible or liable for any redemption of
            Customer’s Nuclei Cash where the redemption was not authorised by
            such Customer.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            <u>Intellectual Property</u>
          </h4>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Copyright and Database Rights
          </h4>
          <p>
            All content included on the Platform, such as photographs, text,
            graphics, logos, button icons, images and software, is the exclusive
            property of the Company, its affiliates or its Suppliers.
          </p>
          <p>
            The compilation of all content on the Platform is the exclusive
            property of the Company or the respective Sellers.
          </p>
          <p>
            All software used on the Platform is the property of the Company or
            its software suppliers. You shall not systematically extract and/or
            re-utilize parts of the contents of the Platform without the
            Company’s express written consent. In particular, you shall not
            utilize any data mining, robots, or similar data gathering and
            extraction tools to extract (either once or a number of times) for
            re-utilization of any substantial parts of the Platform, without the
            Company’s prior and express written consent.
          </p>
          <p>
            You shall not create and/or publish your own database that features
            substantial parts of the Platform (e.g. prices and product listings)
            without the Company’s express written consent. Your personal and
            non-commercial use of the Platform shall be subjected to the
            following restriction (i) you will not modify any content of the
            Platform, including but not limited to, any public display,
            description, performance, sale, or pricing of the services provided
            on the Platform; (ii) you may not decompile, reverse engineer, or
            disassemble the content, or (iii) remove any copyright, trademark
            registration, or other proprietary notices from the content. You
            further agree not to access or use the Platform in any manner that
            may be harmful to the operation of the Platform or its content.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Trademarks
          </h4>
          <p>
            “Gonuclei” marks indicated on the Platform are trademarks or
            registered trademarks of the Company. All other trademarks not owned
            by the Company that appear on the Platform are the property of their
            respective owners, who may or may not be affiliated with, connected
            to, or sponsored by the Company.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Intellectual Property Rights
          </h4>
          <p>
            All materials/content on our Platform (except any third party
            content available on the Platform), including, without limitation,
            names, logos, trademarks, images, text, columns, graphics, videos,
            photographs, illustrations, artwork, software and other elements
            (collectively, "<strong>Materia</strong>l") are protected by
            copyrights, trademarks and/or other intellectual property rights
            owned and controlled by the Company. You acknowledge and agree that
            the Material is made available for limited, non-commercial, personal
            use only. Except as specifically provided herein or elsewhere in our
            Platform, no Material may be copied, reproduced, republished, sold,
            downloaded, posted, transmitted, or distributed in any way, or
            otherwise used for any purpose other than the purposes stated under
            this Terms of Use, by any person or entity, without Company’s prior
            express written permission. You may not add, delete, distort, or
            otherwise modify the Material. Any unauthorized attempt to modify
            any Material, to defeat or circumvent any security features, or to
            utilize our Platform or any part of the Material for any purpose
            other than its intended purposes is strictly prohibited. Subject to
            the above restrictions under this Clause, the Company hereby grants
            You a non-exclusive, freely revocable (upon notice from the
            Company), non-transferable access to view the Material on the
            Platform.
          </p>
          <p>
            The Platform and process, and their selection and arrangement,
            including but not limited to, all text, videos, graphics, user
            interfaces, visual interfaces, sounds and music (if any), artwork,
            algorithm and computer code (and any combination thereof), except
            any third party software available on the Platform, is owned by the
            Company ("
            <strong>Company Property</strong>") and the design, structure,
            selection, co-ordination, expression, look and feel and arrangement
            of such Company Property is protected by copyright, patent and
            trademark laws, and various other intellectual property rights. You
            are not permitted to use Company Property without the prior written
            consent of Company.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Communications
          </h4>
          <p>
            Visiting the Platform or sending emails to the Company constitutes
            electronic communication by you with the Company. The Company
            communicates with you by email or by posting notices on the
            Platform. For contractual purposes, you consent to receive
            communications from the Company electronically and agree that all
            agreements, notices, disclosures and other communications that are
            provided to you electronically satisfy any legal requirement
            stipulating that those communications be in writing. This condition
            does not affect your statutory rights.
          </p>
          <p>
            You understand that upon your use of the Platform, you may receive
            short message service (“<strong>SMS</strong>”) messages from the
            Company or its partners on your registered mobile number. These
            messages could relate to your registration, or any updates and
            promotions that are undertaken by the Company. Please note that the
            Company will send these SMS messages only to the registered mobile
            number or such other number that you may designate for any
            particular purpose.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Termination
          </h4>
          <p>
            At all times during the applicability of these Terms of Use we
            retain the right to terminate these Terms of Use.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Amendments
          </h4>
          <p>
            The Company reserves the right to make changes to our Platform,
            policies, and these Terms of Use at any time. You will be subject to
            the Terms of Use in force at the time that you use the Platform or
            at the time you place orders on the Platform, unless any change to
            those policies or these conditions is required to be made by law or
            government authority (in which case it will apply to orders
            previously placed by you). If any of these conditions are deemed
            invalid, void, or for any reason unenforceable, such condition will
            be deemed severable and will not affect the validity and
            enforceability of any remaining condition.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Disclaimer, Indemnity and Limitation of Liability
          </h4>
          <p>You expressly understand and agree that:</p>
          <ul style={{ listStyleType: 'lower-alpha' }}>
            <li>
              The information, content and materials on the Platform and / or
              Services are provided on an "as is" and "as available" basis. The
              Company and all its subsidiaries, affiliates, officers, employees,
              agents, partners and licensors disclaim all warranties of any
              kind, either express or implied, including but not limited to,
              implied warranties on merchantability, fitness for a particular
              purpose and non-infringement. The content of the Platform has been
              provided in good faith. The content may contain inaccuracies or
              typographical errors.
            </li>
            <li>
              Without limiting the foregoing, Company makes no warranty that (i)
              the Platform, Services will meet your requirements, or your use of
              the Platform will be uninterrupted, timely, secure or error-free;
              (ii) the quality of the Platform will meet your expectations; or
              (iii) any errors or defects in the Platform will be corrected. No
              advice or information, whether oral or written, obtained by you
              from Company shall not create any warranty.
            </li>
            <li>
              The Company has made all reasonable efforts to ensure that all the
              information on the Platform is correct, but the Company neither
              warrants nor makes any representations regarding the quality,
              accuracy or completeness of any data or information provided on
              the Platform. The Company makes no warranty, express or implied,
              concerning the website and/or its contents and disclaims all
              warranties of fitness for a particular purpose and warranties of
              merchantability in respect of the services, including any
              liability, responsibility or any other claim, whatsoever, in
              respect of any loss, whether direct or consequential, to any user
              or any other person, arising out of or from the use of the
              information contained on the Platform.
            </li>
            <li>
              The Company does not warrant that the functions contained in
              content, information and materials on the Platform and / or
              Services, including, without limitation any third party sites or
              services linked to the Platform and / or Services will be
              uninterrupted, timely or error-free, that the defects will be
              rectified, or that the Platform or the servers that make such
              content, information and materials available are free of viruses
              or other harmful components.
            </li>
            <li>
              Any material downloaded or otherwise obtained through the Platform
              and / or Services are accessed at your own risk, and you will be
              solely responsible for any damage or loss of data that results
              from such download to your computer system.
            </li>
            <li>
              You acknowledge that when you access a link that leaves the
              Platform, the site you will enter into is not controlled by the
              Company and different terms of use and privacy policy may apply.
              By accessing links to other sites, you acknowledge that the
              Company is not responsible for those sites. The Company reserves
              the right to disable links from third-party sites to the Platform,
              although the Company is under no obligation to do so.
            </li>
            <li>
              You hereby indemnify, defend, and hold the Company, the Company's
              distributors, agents, representatives and other authorized users,
              and each of the foregoing entities' respective resellers,
              distributors, service providers and suppliers, and all of the
              foregoing entities' respective officers, directors, owners,
              employees, agents, representatives ("<strong>Indemnitees</strong>
              ”), harmless from and against any and all losses, damages,
              liabilities and costs (including legal fees and disbursements in
              connection therewith and interest chargeable thereon) asserted
              against or incurred by the Indemnitees that arise out of, result
              from, or may be payable by virtue of, any breach or
              non-performance of any obligation, covenant, representation or
              warranty by you pursuant to these Terms of Use. Further, you agree
              to hold the Indemnitees harmless against any claims made by any
              third party due to, or arising out of, or in connection with, your
              use of the Platform, Services, any misrepresentation with respect
              to the data or information provided by you in relation to the
              Platform, your violation of the Terms of Use, or your violation of
              any rights of another, including any intellectual property rights.
            </li>
            <li>
              You expressly understand that under no circumstances, including,
              but not limited to, negligence, shall the Company be liable to you
              or any other person or entity for any direct, indirect,
              incidental, special, or consequential damages, including, but not
              limited to damages for loss of profits, goodwill, use, data or
              other intangible losses, resulting from circumstances, including
              but not limited to: (i) the use or the inability to use the
              Platform and / or services; or (ii) the cost of procurement of
              substitute goods and services resulting from any goods, data,
              information or services purchased or obtained or messages received
              or transactions entered into through or from the Platform and / or
              services or (iii) unauthorized access to or alteration of the
              users’ transmissions or data; (iv) statements or conduct of any
              third party on the Platform and / or services; or (v) any other
              matter relating to the Platform and / or services.
            </li>
            <li>
              The Company or any of the Indemnitees respective resellers,
              distributors, service providers and suppliers be relieved of all
              its responsibilities, if any, in the event of failure of
              performance resulting directly or indirectly from an act of force
              majeure or causes beyond the Company's reasonable control
              including, without limitation, acts of god, war, equipment and
              technical failures, electrical power failures or fluctuations,
              strikes, labour disputes, riots, civil disturbances, shortages of
              labour or materials, natural disasters, orders of domestic or
              foreign courts or tribunals, non-performance of third parties, or
              any reasons beyond the reasonable control of the company or any of
              the foregoing entities' respective resellers, distributors,
              service providers and suppliers. You further acknowledge and agree
              that neither the Company nor any of the foregoing entities'
              respective resellers, distributors, service providers and
              suppliers are responsible or liable for (a) any incompatibility
              between the Platform and / or services and any other site,
              service, software or hardware or (b) any delays or failures that
              users may experience with any transmissions or transactions
              relating to the Platform in an accurate or timely manner.
            </li>
            <li>
              Subject to applicable laws, in no event will Company or its
              employees aggregate liability, arising from or related to the
              Services or the use of the Platform shall not exceed the amount
              paid by you for the particular Service availed by you for any and
              all causes of actions brought by you or on behalf of you.
            </li>
          </ul>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Waiver
          </h4>
          <p>
            {' '}
            No term of these Terms of Use shall be deemed waived and no breach
            excused, unless such waiver or consent is in writing and signed by
            the Company. Any consent by the Company to, or waiver of a breach by
            you, whether expressed or implied, shall not constitute consent to,
            waiver of, or excuse for any other different or subsequent breach.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Governing Law and Jurisdiction
          </h4>
          <p>
            Any dispute, claim or controversy arising out of, or relating to the
            breach, termination, enforcement, interpretation or validity
            thereof, including the determination of the scope or applicability
            of these Terms of Use, or to your use of the Platform or the Service
            or information to which it gives access, shall be determined by
            arbitration in India, before a sole arbitrator. Arbitration shall be
            conducted in accordance with the Arbitration and Conciliation Act,
            1996. The seat of such arbitration shall be Bangalore. All
            proceedings of such arbitration, including, without limitation, any
            awards, shall be in the English language. The award shall be final
            and binding on the parties.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Severability
          </h4>
          <p>
            If any provision of these Terms of Use is held by a court of
            competent jurisdiction or arbitral tribunal to be unenforceable
            under applicable law, then such provision shall be excluded from
            these Terms of Use and the remainder of these Terms of Use shall be
            interpreted as if such provision were so excluded and shall be
            enforceable in accordance with its terms; provided however that, in
            such event these Terms of Use shall be interpreted so as to give
            effect, to the greatest extent consistent with and permitted by
            applicable law, to the meaning and intention of the excluded
            provision as determined by such court of competent jurisdiction or
            arbitral tribunal.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Complete Understanding
          </h4>
          <p>
            These Terms of Use contain the entire understanding between the
            parties, and there are no other written or oral understandings or
            promises between the parties with respect to the subject matter of
            these Terms of Use other than those contained or referenced in these
            Terms of Use.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Assignment
          </h4>
          <p>
            You cannot assign or otherwise transfer the Terms of Use, or any
            rights granted hereunder to any third party. Company’s rights under
            the Terms of Use are freely transferable by the Company to any third
            party without the requirement of informing You or seeking Your
            consent.
          </p>
          <h4
            style={{
              fontSize: '20px',
              fontWeight: '700',
              padding: '20px 10px 20px 0px',
            }}
          >
            Privacy statement
          </h4>
          <p>
            You understand that through your use of the Application or any
            software, services or application programming interfaces available
            on or through the Application, including in user forums or blogs on
            or accessed via the Application, you consent to the collection,
            storage, sharing and use by us or any of our affiliates, of Personal
            Information collected from you. You agree that any such Personal
            Information may be viewed by others. You understand and acknowledge
            that if you post personal information online that is accessible to
            the public, you may receive unsolicited messages from other parties
            in return and the Company will not be responsible for the use or
            misuse of such information by third parties. We expressly disclaim
            any legal liability, whether civil, criminal, pertaining to the
            content of, security of, or rights in, any part of such information.
            You have the right to control your Personal Information and except
            as set out in these Terms of Use or in the privacy policy of the
            Company, we will not disclose your Personal Information unless we
            have your permission or unless we are required to or permitted to do
            so under applicable laws.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TermsNconditions;
